import { HomeIcon, UsersIcon } from '@heroicons/react/24/outline'
import { navigate } from 'gatsby';
import React, { useEffect, useState } from "react";

const navigation = [
  { name: 'Home', icon: HomeIcon, href: '#', path: 'home'},
  { name: 'Tools', icon: UsersIcon, href: '#', path: 'tools/tools_dashboard'},
  { name: 'Insights', icon: UsersIcon, href: '#', path: 'insights/insights_dashboard'},
  { name: 'Resources', icon: UsersIcon, href: '#', path: 'resources'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  const [currentTab, setCurrentTab] = useState("")

  useEffect(() => {
    const currentPath = window.location.pathname;
    for (const tab of navigation) {
      if (currentPath.includes(tab.name.toLowerCase())) setCurrentTab(tab.name);
    }
  }, []);

  const clickedTab = (tabName: string, path:string) => {
    setCurrentTab(tabName);
    navigate(`/northstar/${path}`)
  }

  return (
    <div className="hidden md:flex flex-col overflow-y-auto bg-white pt-5 pb-4 min-w-[250px]">
      <div className="mt-5 flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
          {navigation.map((item) => (
            <div
              key={item.name}
              className={classNames(
                item.name === currentTab ? 'font-bold text-black border-l-4 border-l-orange' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group flex items-center px-4 py-2 text-sm font-medium cursor-pointer'
              )}
              onClick={()=>clickedTab(item.name, item.path)}
            >
              <span className="flex-1">{item.name}</span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}
