import axios from 'axios';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';

const returnToLogin = () => {
    Cookies.remove("tableauUserElev");
    Cookies.remove("tableauJwtElev");
    Cookies.remove("tableauApiTokenElev");
    navigate("/northstar/login")
}

const northstarClient = {
    get: async (url: string, authToken = null) => {
        try {
            const tableauSessionToken = Cookies.get("tableauApiTokenElev");
            const res = await axios.get(
                url,
                {
                    headers: {
                        "auth-token": authToken || tableauSessionToken
                    }
                }
            );
            return res.data;
        }
        catch (e) {
            if (e.response.status === 401) returnToLogin();
            // TODO: ADD ERROR ALERTING
        };
    },
    getWorkbookThumbnail: async (url: string, workbookId: string) => {
        try {
            const res = await axios.get(
                url,
                {
                    headers: {
                        "auth-token": Cookies.get("tableauApiTokenElev"),
                    },
                    params: {
                        "workbookId": workbookId,
                    }
                },
            );

            return res.data;
        }
        catch (e) {
            if (e.response.status === 401) returnToLogin();
            // TODO: ADD ERROR ALERTING
        };
    },
    getViewThumbnail: async (url: string, viewId: number, workbookId: number) => {
        try {
            const tableauSessionToken = Cookies.get("tableauApiTokenElev");
            const viewImage = await fetch(
                url,
                {
                    headers: {
                        "Accept": "image/png",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        token: tableauSessionToken,
                        viewId: viewId,
                        workbookId: workbookId
                    })
                }
            );
            return viewImage;
        }
        catch (e) {
            if (e.response.status === 401) returnToLogin();
            // TODO: ADD ERROR ALERTING
        };

    },
    post: async (url: string, body: {}, addAuthToken = true) => {
        try {
            const tableauSessionToken = Cookies.get("tableauApiTokenElev");
            const res = await axios.post(
                url,
                body,
                addAuthToken ? {
                    headers: {
                        "auth-token": tableauSessionToken
                    }
                } : {}
            );
            return res.data;
        }
        catch (e) {
            if (e.response.status === 401) returnToLogin();
            // TODO: ADD ERROR ALERTING
        };
    }
};

export default northstarClient;